import {EventsHttpClient} from '@wix/wix-events-commons-statics'

export interface GetComponentDataParams {
  responsive?: boolean
  locale: string
  language: string
  compId: string
  viewMode: string
}

export interface GetComponentDataResponse {
  component: wix.events.editor.WebComponent
  siteSettings: wix.events.editor.SiteSettings
}

export const getComponentData = (api: EventsHttpClient) => async ({
  responsive,
  locale,
  language,
  compId,
  viewMode,
}: GetComponentDataParams): Promise<GetComponentDataResponse> =>
  api.get(
    `/html/members-page-data?viewMode=${viewMode}&locale=${language}&compId=${compId}&responsive=${responsive}&regional=${
      locale || ''
    }`,
  )
